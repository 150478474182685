<template>
<b-card class="w-100 mb-4" no-body>
    <div class="p-35">
        <div class="d-flex align-items-start">
            <div>
                <h4 class="card-title mb-1">Add Service</h4>
                <b-card-text class="text-muted">
                </b-card-text>
            </div>
        </div>
        <b-alert
        variant="success"
        class="d-flex align-items-center bt-alert"
        :show="success"
        >
        {{ successMsg }}
        </b-alert>
        <b-alert
        variant="danger"
        class="d-flex align-items-center bt-alert"
        :show="error"
        >
        {{ errorMsg }}
        </b-alert>
    </div>
    <hr class="m-0" />
    <b-card-body>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Department </label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-select :options="departments" v-model="form.department_id" ></b-form-select><b-form-invalid-feedback>This field is required</b-form-invalid-feedback></b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Name</label>
            </b-col>
            <b-col sm="9">
                <b-form-group>
                <b-form-input placeholder="Name" type="text" v-model="$v.form.name.$model"
                            :state="validateState('name')"
                          ></b-form-input>
                          <b-form-invalid-feedback id="txt-fullname"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Registration</label>
            </b-col>
            <b-col sm="9">
                <b-form-group>
                <b-form-input placeholder="Registration" type="number" v-model="$v.form.registration.$model"
                            :state="validateState('registration')"
                          ></b-form-input>
                          <b-form-invalid-feedback id="txt-fullname"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Comments</label>
            </b-col>
            <b-col sm="9">
                <b-form-group>
                <b-form-textarea id="comments" placeholder="Name" type="text" v-model="$v.form.comments.$model"
                            :state="validateState('comments')"
                          ></b-form-textarea>
                          <b-form-invalid-feedback id="txt-fullname"
                            >Only 200 Characters Required</b-form-invalid-feedback
                          >
                        </b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Status</label>
            </b-col>
            <b-col sm="9">
                <b-form-select :options="sellstatus" v-model="form.product_status_id"></b-form-select>
            </b-col>
        </b-row>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Sub-Category </label>
            </b-col>
            <b-col sm="9">
                <b-form-group>
                <b-form-select v-model="$v.form.category_id.$model" :state="validateState('category_id')" :options="subcategories"></b-form-select>
                <b-form-invalid-feedback>This field is required</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Price</label>
            </b-col>
            <b-col sm="9">
                <b-form-group>
                <b-form-input id="name" type="number" v-model="$v.form.rate_price.$model" :state="validateState('rate_price')" placeholder="e.g 650000" required></b-form-input>
                <b-form-invalid-feedback id="txt-fullname"
                            >This is a required field</b-form-invalid-feedback
                          >
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Price Unit</label>
            </b-col>
            <b-col sm="9">
                <b-form-group>
                <b-form-input id="name" type="text" v-model="$v.form.rate_unit.$model" :state="validateState('rate_unit')" placeholder="e.g Hourly" required></b-form-input>
                <b-form-invalid-feedback id="txt-fullname"
                            >This is a required field</b-form-invalid-feedback
                          >
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Monthly Discount %</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="name" type="number" v-model="$v.form.monthly_discount.$model" :state="validateState('monthly_discount')" placeholder="e.g 10" required></b-form-input>
                <b-form-invalid-feedback>This field is required</b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Yearly Discount %</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="name" type="number" v-model="$v.form.yearly_discount.$model" :state="validateState('yearly_discount')" placeholder="e.g 10" required></b-form-input>
                <b-form-invalid-feedback>This field is required</b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Initial Start time</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="name" type="text" v-model="form.initial_time" placeholder="e.g 10"></b-form-input>
                <b-form-invalid-feedback>This field is required</b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Add Calendar</label>
            </b-col>
            <b-col sm="9">
                <b-form-select v-model="$v.form.calendar_template_id.$model" :state="validateState('calendar_template_id')" :options="calendars"></b-form-select>
                <b-form-invalid-feedback>This field is required</b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Location</label>
            </b-col>
            <b-col sm="9">
                <b-form-select v-model="$v.form.location_id.$model" :state="validateState('location_id')" :options="locations"></b-form-select>
                <b-form-invalid-feedback>This field is required</b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Expereince Year</label>
            </b-col>
            <b-col sm="9">
                <b-form-input id="year" type="text"  v-model="$v.form.experience_years.$model" :state="validateState('experience_years')" placeholder="e.g. 1998" required></b-form-input>
                <b-form-invalid-feedback>This field is required</b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0">Success Scope</label>
            </b-col>
            <b-col sm="9">
                <b-form-select v-model="$v.form.success_score.$model" :state="validateState('success_score')" :options="score"></b-form-select>
                <b-form-invalid-feedback>This field is required</b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Fitness Certificate </label>
            </b-col>
            <b-col sm="9">
                <b-form-file v-model="$v.form.fitness_certificate.$model" id="file"></b-form-file>
                <b-form-invalid-feedback>This field is required</b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Cover Image</label>
            </b-col>
            <b-col sm="4">
                <b-form-file v-model="form.terms" id="file"></b-form-file>
            </b-col>
            <b-col sm="2">
                <b-spinner v-if="uploading" label="Spinning"></b-spinner>
                <b-button v-else pill variant="success" class="" @click="uplaodFile()">Upload</b-button>
            </b-col>
            <b-col sm="3">
                <span>{{ form.terms!=="none"? form.terms:"No Image Found" }}</span>
            </b-col>
        </b-row>
    </b-card-body>
    <div class="p-35 border-top">
        <div class="btn-grp text-right">
            <b-button pill variant="primary" class="px-4" @click="saveServ()">Save</b-button>
            <b-button pill variant="danger" class="px-4">Cancel</b-button>
        </div>
    </div>
</b-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
} from "vuelidate/lib/validators";
export default {
    name: "Add_Service",
    mixins: [validationMixin],
    data: () => ({
        sale: false,
        rent: false,
        error: false,
        success: false,
        errorMsg: null,
        successMsg: null,
        uploading: false,
        form:{
            department_id: null,
            user_id: null,
            product_status_id: null,
            location_id: null,
            category_id: null,
            calendar_template_id: null,
            name: null,
            registration: null,
            experience_years: null,
            rate_price: null,
            rate_unit: null,
            monthly_discount: null,
            yearly_discount: null,
            success_score: null,
            fitness_certificate: "none",
            specification: "none",
            terms: "none",
            comments: null,
            initial_time: null,
            cover_image:"none",
            image_1:"none",
            image_2:"none",
            image_3:"none"
        },
        categories: [],
        score: [{
                value: null,
                text: 'Please select an option'
            },
            {
                value: 1,
                text: '1'
            },
            {
                value: 2,
                text: '2'
            },
            {
                value: 3,
                text: '3'
            },
            {
                value: 4,
                text: '4'
            },
            {
                value: 5,
                text: '5'
            },
        ],
        calendars: [],
        departments: [],
        locations: [],
        subcategories: [],
        sellstatus: [],
        operationstatus: [],
        product_type: []
    }),
    computed:{
        ...mapState({
            authId: (state) => state.auth.authId
        })
    },
    mounted() {
        this.initComp();
    },
    validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(20),
      },
      registration: {
        required,
        maxLength: maxLength(20),
      },
      comments:{
        required,
        maxLength: maxLength(200),
      },
      category_id:{
        required,
      },
      rate_price:{
        required,
      },
      rate_unit:{
        required,
      },
      monthly_discount:{required},
      yearly_discount:{required},
      calendar_template_id:{required},
      location_id:{required},
      experience_years:{
          maxLength: maxLength(4),
          required
          },
      success_score:{required},
      fitness_certificate:{required},
            specification: {required},
            terms: {required},
    },
  },
    methods: {
    ...mapActions(["getDepartements","getCalendarsByUser","getLocations","getProductStatuses","getCategories","saveService","saveFile"]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    initComp(){
            this.getLocations().then(res => {
                res.forEach(element => {
                   this.locations.push({ value: element.id, text: element.name }); 
                });
            });
            this.getProductStatuses().then(res => {
                res.forEach(element => {
                   this.sellstatus.push({ value: element.id, text: element.name }); 
                });
            });
            this.getCategories().then(res => {
                res.forEach(element => {
                   if(element.parent_id !== null){
                       this.subcategories.push({ value: element.id, text: element.name });
                   }
                   else{
                       this.categories.push({ value: element.id, text: element.name });
                   } 
                });
            });
            this.getCalendarsByUser(this.authId).then((res) => {
                res.forEach(element => {
                   this.calendars.push({ value: element.id, text: element.name }); 
                });
            });
            this.getDepartements().then((res) => {
                let items = res.filter(d => d.user_id === this.authId);
                items.forEach(element => {
                   this.departments.push({ value: element.id, text: element.department_name }); 
                });
            });
        },
        uplaodFile(){
            this.uploading = true; 
            const formData = new FormData();
            formData.append("file", this.form.terms);
            this.saveFile(formData).then((res) => {
                if(res.status === 200){
                    this.form.terms = res.data;
                    this.uploading = false;
                }
            })
        },
        saveServ(){
            this.form.user_id = this.authId;
            this.saveService(this.form).then(res => {
                if(res.status === 400){
                    this.errorMsg = res.data;
                    //this.error = true;
                    this.$bvToast.toast(this.errorMsg, {
                        title: "Failed",
                        variant: 'danger',
                        solid: true
                    })
                }
                else{
                    this.successMsg = res;
                    //this.success = true;
                    this.$bvToast.toast(this.successMsg, {
                        title: "Success",
                        variant: 'success',
                        solid: true
                    })
                }
            })
        }
    }
};
</script>
